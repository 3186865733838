import React from 'react';
import Layout from '../components/Layout';
import Content from '../components/Content';

export default function NotFound() {
  return (
    <Layout page="404 not found">
      <Content>
        <p>This page does not exist, please use the site navigation.</p>
      </Content>
    </Layout>
  );
}
